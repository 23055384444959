<template>
  <div class="joinus">
    <header v-if="IsPC()">
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner3.png"
        alt=""
      />
      <h4>期待你的加入</h4>
      <p>让我们一起用热血，铸就新的网营时代！</p>
    </header>
    <header v-else>
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner-h5.png"
        alt=""
      />
      <h4>期待你的加入</h4>
      <p>让我们一起用热血，铸就新的网营时代！</p>
    </header>
    <div class="bodyer">
      <div class="text">
        <p>在这里我们一同成长</p>
        <i></i>
        <li>1. 入职“专属服务”，新人训“快速融入”，岗位培训“1对1带教”</li>
        <li>2. 网营大讲堂“快速成长秘技”，专项训练营“定制化课程服务”</li>
        <li>3. GBD演讲俱乐部“发掘你的领袖潜质”，心理咨询室“承包你的烦恼”</li>
      </div>
      <div class="text">
        <p>一边工作一边快乐</p>
        <i></i>
        <li>1. 生日会“你的每一个生日我都记得”</li>
        <li>2. 下午茶“再大的难题，一顿下午茶就够了”</li>
        <li>3. 球类俱乐部“篮球、足球、乒乓球，你要的球我都有”</li>
        <li>4. 周年陈“荣耀和自信，分分钟被激发”</li>
        <li>5. 年会“公司给你一个机会，放肆high”</li>
        <li>6. OUTING“去看世界么，小伙伴陪你一起”</li>
      </div>
      <div class="info" v-if="IsPC()">
        <p class="info1">简历请投递至邮箱 <br />hr@netopstec.com</p>
        <a
          href="https://wwwwangyingcom.zhiye.com/"
          ><p class="info2">点击<br />投递简历</p></a
        >

        <img
          src="https://prod.oss.netopstec.com/net-joinus.png"
          alt=""
        />
      </div>
      <div v-else class="info">
        <p class="info1">简历请投递至邮箱 <br />hr@netopstec.com</p>

        <a href="https://wwwwangyingcom.zhiye.com/"
          ><p class="info2">投递<br />简历</p></a
        >
        <img
          src="https://prod.oss.netopstec.com/net-joinus.png"
          alt=""
        />
      </div>
    </div>
    <div ref="box_title" class="box_title">
      <h6>热招岗位</h6>
    </div>
    <div ref="main" class="main">
      <b></b>
      <div class="list">
        <li @click="handleIndex(1)">
          <span>商务BD</span>
          <i v-if="tabsIndex == 1" class="gang"></i>
        </li>
        <li @click="handleIndex(2)">
          <span>推广运营</span>
          <i v-if="tabsIndex == 2" class="gang"></i>
        </li>
        <li @click="handleIndex(3)">
          <span>CRM运营</span>
          <i v-if="tabsIndex == 3" class="gang"></i>
        </li>
        <li @click="handleIndex(4)">
          <span>电商视觉设计师</span>
          <i v-if="tabsIndex == 4" class="gang"></i>
        </li>
        <li @click="handleIndex(5)">
          <span>活动运营</span>
          <i v-if="tabsIndex == 5" class="gang"></i>
        </li>
        <li @click="handleIndex(6)">
          <span>新媒体运营</span>
          <i v-if="tabsIndex == 6" class="gang"></i>
        </li>
        <!-- <li @click="handleIndex(7)">
          <span>售后客服</span>
          <i v-if="tabsIndex == 7" class="gang"></i>
        </li>
        <li @click="handleIndex(8)">
          <span>数据分析师</span>
          <i v-if="tabsIndex == 8" class="gang"></i>
        </li>
        <li @click="handleIndex(9)">
          <span>CRM运营</span>
          <i v-if="tabsIndex == 9" class="gang"></i>
        </li> -->
      </div>
      <div v-if="tabsIndex == 1" class="text">
        <div class="top">
          <p>商务BD</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1． 全渠道电商渠道运营/各生态整体营销解决方案的销售；</li>
            <li>2． 主要面向全球一线知名品牌客户，行业领域涉及母婴、宠物、服饰、美妆、食品等。需要有能力与客户Global/Local高层平等对话，推动项目落地；</li>
            <li>3． 负责商务谈判，主导客户会议，达成线上电商合作，包括但不限于品牌代运营、营销、设计、客服等；</li>
            <li>4． 负责中大客户的跟进与客情关系维护；</li>
          </div>
          <div class="right">
            <p>任职资格</p>
            <li>1． 3年以上相关工作经验，形象气质佳；</li>
            <li>2． 行业经验丰富，以化妆品、母婴、服饰、食品行业优先，有相关客户资源优先；</li>
            <li>3． 有激情，思维敏捷，自我驱动，有较强的沟通谈判能力；</li>
            <li>4． 能适应出差，抗压力强；</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 2" class="text">
        <div class="top">
          <p>推广运营</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．【定策略】负责品牌年度月度等阿里妈妈推广方案拟定，清晰运营目标，投放打法明确；</li>
            <li>2．【强实操】紧密结合店铺运营情况，实操钻展、直通车、超推等投放工具；</li>
            <li>3．【懂分析】分析投放数据，通过渠道/货品/人群/创意等数据情况，得出优化动作，提升投放效果；</li>
            <li>4．【乐分享】有干货，日常分享投放干货经验，团队共同提升；</li>
          </div>
          <div class="right">
            <p>任职资格</p>
            <li>1．精通淘系品牌及效果广告推广，2年及以上推广经验，有TP行业推广经验优先；</li>
            <li>2．具备较强的数据分析与逻辑思维能力、表达能力强，能够通过专业的推广知识及清晰的思维，分析解决业务问题；</li>
            <li>3．抗压能力强，能与品牌方平等对话。工作充满热情、正能量，团队合作意识强；</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 3" class="text">
        <div class="top">
          <p>CRM运营</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．负责品牌天猫旗舰店、京东、微信、抖音等平台（其中）的客户关系、会员管理体系的建立和完善，提升用户消费体验，增加会员数与销售额；</li>
            <li>2．深入分析用户群体，了解用户购物体验、产品使用体验情况，发掘潜在机会，支持业务发展；</li>
            <li>3．负责会员生命周期管理，提高会员活跃度，提高会员价值贡献率；</li>
            <li>4．会员精准营销体系制定，提升CRM营销活动ROI；</li>
          </div>
          <div class="right">
            <p>岗位要求</p>
            <li>1．大专以上学历，专业不限；</li>
            <li>2．有2年以上会员管理工作经验者优先；</li>
            <li>3．具备良好的数据处理能力，熟练使用办公软件，有PPT特长；</li>
            <li>4．团队合作能力、沟通表达能力好；</li>
            <li>5．爱学习、爱生活、充满正能量，喜欢互联网新鲜玩法互动；</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 4" class="text">
        <div class="top">
          <p>电商视觉设计师</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．负责品牌天猫、京东店铺装修，对色彩、布局、广告视觉营销有独特视角及观点，提升用户体验；</li>
            <li>2．负责制作商品详情页，详情页面产品资料和信息，对所店铺所销售的产品进行描述的策划及设计，包括对商品卖点挖掘、产品比较、功能描述等设计；</li>
            <li>3．负责店铺促销期的设计排版及促销宣传版面，配合推广制作推广宣传图、直通车及钻石展位推广图片等；店铺网页的设计、专题banner、页面的美化、各种活动及专题页面的制作；</li>
          </div>
          <div class="right">
            <p>岗位要求</p>
            <li>1．本科及以上学历，美术、设计或相关专业本科以上学历(优秀人才，专业和学历可适当放宽）；</li>
            <li>2．从事电商设计行业工作1-3年，对视觉用户研究有一定接触和见解；</li>
            <li>3．热心于品牌视觉设计,善于把握潮流动向；</li>
            <li>4．具备良好合作态度及团队精神，并富有工作激情、创新欲望和责任感，能承受工作压力；</li>
            <li>5．简历请附上作品或相关链接；</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 5" class="text">
        <div class="top">
          <p>活动运营</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．了解淘宝天猫平台的规则和主要玩法（大促形式，招商准则，促销模式，消费者特性等）；</li>
            <li>2．负责品牌天猫官旗所有活动的策划、执行、后台报名、并能及时作出活动效果分析和反馈等相关内容；</li>
            <li>3．充分了解品牌的产品和品牌特性，并能整合品牌，平台资源，制定出终端促销方案，拉动品牌终端销量，协助完成店铺的月度、季度、年度运营方案中涉及到活动运营相关的内容；</li>
            <li>4．负责对接各个横向模块，确保活动落地及信息同步；需重点关注会员及推广端的提效；</li>
            <li>5．挖掘挖掘和分析用户习惯及对标竞品，布局页面的逻辑合理性及美观度；</li>
            <li>6．负责对接店铺小二、品牌相关对接人，有良好的表达和沟通能力；</li>
          </div>
          <div class="right">
            <p>岗位要求</p>
            <li>1．大专及以上学历，3年以上电商平台活动运营工作经验，有一定成功案例；</li>
            <li>2．具有良好的跨部门协调沟通能力，并能积极推动，高效执行；</li>
            <li>3．了解天猫、淘系或京东等平台营销节奏及店铺类营销玩法；</li>
            <li>4．了解电子商务平台及发展趋势，精通电子商务平台营销工具的应用能力；</li>
            <li>5．吃苦耐劳，抗压能力强；具有创新意识和团队合作意识；</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 6" class="text">
        <div class="top">
          <p>新媒体运营</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．负责品牌官方账号的日常运营，话题营销，提升粉丝活跃度，对粉丝进行有效管理和维护；</li>
            <li>2．负责品牌官方账号内容整体规划，跟踪推广效果，通过运营数据调整投放策略，提升品牌内容质量，以及品牌曝光度；</li>
            <li>3．负责新媒体平台的种草内容编辑，排版推送，活动策划、文案撰写，推广发布的运营工作；</li>
            <li>4．挖掘和分析用户使用习惯，情感及体验感受，洞察用户要求，即使掌握最新热点，策划新的营销方向；</li>
            <li>5．开拓kol资源库：负责抖音、小红书kol/koc的资源拓展、维护；</li>
          </div>
          <div class="right">
            <p>任职要求</p>
            <li>1．小红书、抖音重度用户，有养宠经验，对宠物品牌有一定的了解；</li>
            <li>2．2年以上品牌方官号运营经验，熟悉小红书、抖音运营规则，有成功案例作品者优先；</li>
            <li>3．有较强的逻辑能力，业绩指标规划能力，结果导向思维；不断优化自身的推广策略，复盘总结经验，形成有效方法论；</li>
            <li>4．具备独立选题策划能力，实时把握热点；文案撰写能力优秀；</li>
          </div>
        </div>
      </div>
      <!-- <div v-if="tabsIndex == 7" class="text">
        <div class="top">
          <p>售后客服</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>1．负责天猫、京东等电商平台的售后客户服务工作。</li>
            <li>2．处理退换货、退款、产品损坏等问题。</li>
            <li>3．管理淘宝后台：处理投诉、举报、侵权、买家维权等。</li>
            <li>4．各种售后问题的登记、原因查明，并每周、每月总结。</li>
            <li>5．完成客服主管安排的其它事情。</li>
          </div>
          <div class="right">
            <p>任职资格</p>
            <li>1．大专以上学历，打字速度快。</li>
            <li>
              2．头脑灵活,沟通能力强,能随机应变与各种客户交流和处理售后问题。
            </li>
            <li>3．具备责任心，耐心，用心，细心。</li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 8" class="text">
        <div class="top">
          <p>数据分析师</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>
              1．通过阿里数据银行进行品牌店铺人群洞察和分析，对品牌AIPL人群进行分析，给与运营及投放建议。
            </li>
            <li>
              2．利用数据银行、策略中心和内部数据平台工具定性与定量分析、用户研究、竞品研究，
              数据分析评估品牌数字营销活动效果，并为品牌提出营销/货品优化建议。
            </li>
            <li>
              3．结合品牌数据银行及新客策多维度的数据分析，产出数据分析洞察报告，月度报告及后续模型优化调整。
            </li>
            <li>
              4．设计品牌的人群资产目标和路径，利用大数据为品牌提供策略分析，基于用户画像及运营需求，提供运营建议。
            </li>
            <li>5．配合上级完成各种相关任务，并实时反馈、监督与改进。</li>
          </div>
          <div class="right">
            <p>任职资格</p>
            <li>
              1．通过阿里数据银行进行品牌店铺人群洞察和分析,通过策略中心数据，分析行业、品牌产品的增长动力。
            </li>
            <li>
              2．能够利用数据银行、策略中心、生意参谋等工具，洞察品类／产品线的消费者画像及消费者行为。
            </li>
            <li>3．能够通过消费者流失分析，结合运营竞品分析，分析优化方向。</li>
            <li>4．有能力进行品牌FAST GROW GMV模型的拆解及预算分配。</li>
            <li>
              5．有整合营销全案的产出和落地相关的实操经验及优秀案例者优先录用，有数据银行分析师证书者优先考虑。
            </li>
          </div>
        </div>
      </div>
      <div v-if="tabsIndex == 9" class="text">
        <div class="top">
          <p>CRM运营</p>
        </div>
        <div class="bottom">
          <div class="left">
            <p>岗位职责</p>
            <li>
              1．负责品牌天猫旗舰店客户关系、会员管理体系的建立和完善，提升用户消费体验，增加会员数与销售额。
            </li>
            <li>
              2．深入分析用户群体，了解用户购物体验、产品使用体验情况，发掘潜在机会，支持业务发展。
            </li>
            <li>
              3．负责会员生命周期管理，提高会员活跃度，提高会员价值贡献率。
            </li>
            <li>4．会员精准营销体系制定，提升CRM营销活动ROI。</li>
          </div>
          <div class="right">
            <p>任职资格</p>
            <li>1．大专以上学历，专业不限。</li>
            <li>2．有会员管理工作经验者优先。</li>
            <li>3．具备良好的数据处理能力，熟练使用办公软件，有PPT特长。</li>
            <li>4．团队合作能力、沟通表达能力好。</li>
            <li>5．爱学习、爱生活、充满正能量，喜欢互联网新鲜玩法互动。</li>
            <li>5．有食品、美妆、宠物相关运营经验也可以聊聊。</li>
          </div>
        </div>
      </div> -->

      <b></b>
    </div>

    <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabsIndex: 1,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleIndex(index) {
      this.tabsIndex = index;
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },

    handleScroll(e) {
      //滚动事件
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (
        scrollTop >=
        this.$refs.box_title.offsetTop - this.$refs.box_title.offsetHeight * 1.7
      ) {
        this.$refs.box_title.className = "box_title box_title_ini";
        this.$refs.main.className = "main main_ini";
      }
    },
    goMokagr() {
      console.log("点击");
      window.location.href = 
        "https://app.mokahr.com/m/apply/netopstec/40338#/home"
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      left: 732px;
      top: 323px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 458px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      left: 176px;
      width: 1569px;
      height: 70px;
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .bodyer {
    position: relative;
    height: 1442px;
    background: url("https://prod.oss.netopstec.com/JOIN US2.png") no-repeat top
      center;
    background-size: 100% 100%;
    overflow: hidden;
    & > .text:nth-child(1) {
      margin-top: 305px;
    }
    .text {
      margin-top: 50px;
      margin-left: 191px;
      opacity: 0;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
      p {
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        line-height: 93px;
      }
      i {
        display: block;
        width: 69px;
        height: 5px;
        background: #c71538;
        margin-bottom: 25px;
      }
      li {
        font-size: 18px;
        color: #ffffff;
        line-height: 35px;
      }
    }
    img {
      opacity: 0;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
      margin-top: 135px;
      margin-left: 191px;
      width: 1519px;
      height: 262px;
    }
    .info1 {
      position: absolute;
      top: 1120px;
      left: 309px;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      line-height: 60px;
      z-index: 1;
      opacity: 0;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
    .info2 {
      width: 130px;
      height: 130px;
      text-align: center;
      background: rgba(93, 16, 28, 0.4);
      border-radius: 20px;
      position: absolute;
      top: 1117px;
      font-size: 30px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      line-height: 45px;
      left: 784px;
      z-index: 1;
      opacity: 0;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
      &:hover {
        background: #5d0e19;
      }
    }
  }
  .box_title {
    margin-top: 110px;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 1920px;
    height: 440px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      width: 100%;
      height: 100%;
      line-height: 440px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 40px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 20px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .main {
    padding-bottom: 100px;
    b {
      display: block;
      margin: 0 auto;
      width: 1518px;
      height: 2px;
      border-top: 1px dashed #666666;
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    .text {
      opacity: 0;
    }
    .list {
      display: flex;
      padding: 0 200px;
      justify-content: space-between;
      opacity: 0;
      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;
        span {
          font-size: 20px;
          font-weight: 400;
          color: #666666;
          line-height: 69px;
        }
        i {
          width: 69px;
          height: 5px;
          background: #c71538;
        }
      }
    }
    .top {
      margin: 50px 200px 20px;
      p {
        font-size: 30px;
        color: #000000;
        line-height: 93px;
      }
      span {
        font-size: 20px;
        color: #666666;
        line-height: 40px;
      }
    }
    .bottom {
      margin: 20px 200px 30px;
      display: flex;
      justify-content: space-between;
      P {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 93px;
      }
      li {
        width: 710px;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }
    }
  }
  .footer {
    width: 1920px;
    height: 175px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      // left: 732px;
      width: 750px;
      text-align: center;
      top: 401px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 548px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      // left: 176px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 651px;
      height: 70px;
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .bodyer {
    position: relative;
    height: 1442px;
    background: url("https://prod.oss.netopstec.com/JOIN-h5.png") no-repeat top
      center;
    padding: 0 50px;
    background-size: 100% 100%;
    overflow: hidden;
    .text {
      margin-top: 122px;
      opacity: 0;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
      p {
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 93px;
      }
      i {
        margin: 0 auto;
        display: block;
        width: 69px;
        height: 5px;
        background: #c71538;
        margin-bottom: 25px;
      }
      li {
        font-size: 18px;
        color: #ffffff;
        line-height: 35px;
      }
    }

    .info {
      position: relative;
      display: flex;
      margin: 135px auto 0;
      width: 650px;
      height: 112px;
      align-items: center;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        animation: introduce_title 1s 0.5s linear 1;
        animation-fill-mode: forwards;
      }
    }
    .info1 {
      width: 204px;
      line-height: 26px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 26px;
      z-index: 1;
      opacity: 0;
      margin-left: 30px;
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
    .info2 {
      position: relative;
      width: 60px;
      height: 60px;
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      border-radius: 5px;
      font-weight: 500;
      display: flex;
      margin-left: 15px;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      z-index: 2;
      opacity: 0;
      background: rgba(93, 16, 28,1);
      animation: introduce_title 1s 0.5s linear 1;
      animation-fill-mode: forwards;
      &:hover {
        background: #5d0e19;
      }
    }
  }
  .box_title {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 750px;
    height: 322px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      width: 350px;
      height: 83px;
      line-height: 83px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 126px;
      opacity: 0;
      font-size: 40px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 20px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .main {
    padding-bottom: 100px;
    b {
      display: block;
      margin: 0 auto;
      width: 700px;
      height: 2px;
      border-top: 1px dashed #666666;
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    .text {
      opacity: 0;
    }
    .list {
      display: flex;
      overflow-x: auto;
      padding: 0 20px;
      opacity: 0;
      li {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;
        span {
          white-space: nowrap;
          font-size: 20px;
          font-weight: 400;
          color: #666666;
          line-height: 69px;
        }
        i {
          width: 69px;
          height: 5px;
          background: #c71538;
        }
      }
    }
    .top {
      margin: 20px 0 0 52px;
      p {
        font-size: 30px;
        color: #000000;
        line-height: 93px;
      }
      span {
        font-size: 20px;
        color: #666666;
        line-height: 40px;
      }
    }
    .bottom {
      margin: 0 0 0 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      P {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 93px;
      }
      li {
        width: 650px;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }
    }
  }
  .footer {
    width: 750px;
    height: 216px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.box_title_ini {
  h6 {
    animation: introduce_title 1s linear 1;
    animation-fill-mode: forwards;
  }
}

.main_ini {
  .list {
    animation: introduce_title 1s 0.3s linear 1;
    animation-fill-mode: forwards;
  }
  .text {
    animation: introduce_title 1s 0.3s linear 1;
    animation-fill-mode: forwards;
  }
}

@keyframes bannerLogo {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>